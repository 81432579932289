import React from 'react';
import CustCarousel from '../components/carousel';
import './Home.css';
import aboutImg from '../assets/images/about-img.svg';

const Home = () => {
  return (
    <>
      <CustCarousel />
      <div class="responsive-container-block bigContainer">
        <div class="responsive-container-block Container bottomContainer">
          <div class="allText bottomText">
            <p class="text-blk headingText">About</p>
            <p class="text-blk subHeadingText">We build to your bespoke</p>
            <p class="text-blk description">
              Welcome to Kirankrafts! Since 2002, we've been your go-to team for
              construction and engineering services. We cover everything from
              designing and building curtain walls, façades, to metal and stone
              cladding, aluminum doors, windows, railings, balusters, and
              canopies. At Kirankrafts, we're all about delivering quality work
              without breaking the bank. We have skilled teams dedicated to
              design, procurement, and construction, ensuring that we get the
              job done right. We keep up with the latest industry trends and use
              cutting-edge technology to make our services better. Our goal is
              simple: provide cost-effective solutions with top-notch quality,
              all while making safety a priority.
            </p>
          </div>
          <div class="videoContainer">
            <img alt="" class="dotsImg image-block" src={aboutImg} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
