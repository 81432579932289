import React, { useState } from 'react';
import './header.css';
import { Link, useLocation } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const handleIconClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav>
      <label htmlFor="" className="logo">
        KiranKrafts
      </label>
      <ul className={isOpen ? 'show' : ''}>
        <li onClick={handleIconClick}>
          <Link className={location.pathname === '/' ? 'active' : ''} to="/">
            Home
          </Link>
        </li>
        <li onClick={handleIconClick}>
          <Link
            className={location.pathname === '/projects' ? 'active' : ''}
            to="/projects"
          >
            Projects
          </Link>
        </li>
        <li onClick={handleIconClick}>
          <Link
            className={location.pathname === '/services' ? 'active' : ''}
            to="/services"
          >
            Services
          </Link>
        </li>
        <li onClick={handleIconClick}>
          <Link
            className={location.pathname === '/contact-us' ? 'active' : ''}
            to="/contact-us"
          >
            Contact Us
          </Link>
        </li>
      </ul>
      <label htmlFor="" id="icon" onClick={handleIconClick}>
        <FaBars />
      </label>
    </nav>
  );
};

export default Header;
