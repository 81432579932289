import React from 'react';
import { Link } from 'react-router-dom';
import './footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <label htmlFor="" className="logo-2">
          KiranKrafts
        </label>
        <div className="footer-links">
          <h2 className="footer-head">Quick Links</h2>
          <Link to="/" className="footer-link">
            Home
          </Link>
          <Link to="/projects" className="footer-link">
            Projects
          </Link>
          <Link to="/services" className="footer-link">
            Services
          </Link>
          <Link to="/contact-us" className="footer-link">
            Contact
          </Link>
        </div>
        <div className="footer-add">
          <h2 className="footer-head">Our Office</h2>
          <p className="footer-text">
            PLOT NO. 59, POCKET-14, SECTOR-24, ROHINI, DELHI-110085
          </p>
        </div>
      </div>
      <div className="c-right">
        <p>© 2024 KiranKrafts All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
