import React from 'react';
import { FaMapMarkerAlt, FaPhone, FaEnvelope } from 'react-icons/fa';
import './Contact.css';

const Contact = () => {
  const contactMethods = [
    {
      icon: <FaMapMarkerAlt className="icon" />,
      contact: 'PLOT NO. 59, POCKET-14, SECTOR-24, ROHINI, DELHI-110085',
      title: 'Our office',
    },
    {
      icon: <FaPhone className="icon" />,
      contact: '+91 9312072287',
      title: 'Phone',
    },
    {
      icon: <FaEnvelope className="icon" />,
      contact: 'crownkedia@gmail.com',
      title: 'Email',
    },
  ];

  return (
    <div className="contact">
      <div className="contact-left">
        <h1>GET IN TOUCH</h1>
        {contactMethods.map((contact, id) => (
          <div className="contact-item" key={id}>
            <h2>
              {contact.icon}
              {contact.title}
            </h2>
            <p>{contact.contact}</p>
          </div>
        ))}
      </div>
      <div className="contact-right">
        <form
          method="POST"
          action="https://docs.google.com/forms/d/e/1FAIpQLSeXB1xOVAy1o_RmMpLJ2e_y0I4zU1yf4pg4yv-PWztvsTDSWQ/formResponse"
        >
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="entry.1021211697"
              placeholder="e.g. Ajay"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="entry.1630070825"
              placeholder="e.g. crownkedia@gmail.com"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone:</label>
            <input
              type="tel"
              id="phone"
              name="entry.301837774"
              placeholder="e.g. +91 xxxxxxxxxx"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message:</label>
            <textarea id="message" name="entry.604988391"></textarea>
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>
      <div className="map">
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3498.7349899668566!2d77.0871666755043!3d28.72746487561303!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjjCsDQzJzM4LjkiTiA3N8KwMDUnMjMuMSJF!5e0!3m2!1sen!2sin!4v1703686129690!5m2!1sen!2sin"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default Contact;
