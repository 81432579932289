import React from 'react';
import './carousel.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import urogyn from '../assets/images/urogyn-hos-1.jpg';
import udyogvihar from '../assets/images/udyogvihar-with-pvtbuilder2.jpg';
import rajasthan from '../assets/images/airoflex-rajasthan-with-APS1.jpg';
import jbc from '../assets/images/jbc-plywood-with-pvt1.jpg';

const CustCarousel = () => {
  return (
    <div className="carousel-container">
      <Carousel
        showArrows={false}
        showIndicators={true}
        showStatus={false}
        showThumbs={false}
        autoPlay={true}
        infiniteLoop={true}
        transitionTime={1000}
        interval={3000}
        dynamicHeight={false}
      >
        <div className="img-div">
          <img src={rajasthan} alt="" />
        </div>
        <div className="img-div">
          <img src={jbc} alt="" />
        </div>
        <div className="img-div">
          <img src={udyogvihar} alt="" />
        </div>
        <div className="img-div">
          <img src={urogyn} alt="" />
        </div>
      </Carousel>
    </div>
  );
};

export default CustCarousel;
