import React from 'react';
import './Services.css';
import servicesData from '../assets/data/servicesData';
import CustCarousel from '../components/carousel';

const Services = () => {
  return (
    <>
      <CustCarousel />
      <div id="service">
        <h1>Services</h1>
        <ul className="service-item">
          {servicesData.map((service, id) => (
            <li key={id}>{service.serviceName}</li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Services;
