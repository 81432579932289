import React from 'react';
import projectsData from '../assets/data/projectsData';
import './Projects.css';

const Projects = () => {
  return (
    <div className="projects-container">
      {projectsData.map((projectData, id) => (
        <div key={id} className="project">
          <h1>{projectData.projectName}</h1>
          <div className="project-imgs">
            {projectData.projectImages.map((projectImg, imgId) => (
              <div key={imgId} className="project-img">
                <img src={projectImg} alt="" />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Projects;
