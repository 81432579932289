const servicesData = [
  {
    id: 1,
    serviceName: 'Unitized Glazing',
  },
  {
    id: 2,
    serviceName: 'Semi Unitized Glazing',
  },
  {
    id: 3,
    serviceName: 'Toggle Glazing',
  },
  {
    id: 4,
    serviceName: 'Conventional Curtain Wall Glazing',
  },
  {
    id: 5,
    serviceName: 'Skylight',
  },
  {
    id: 6,
    serviceName: 'Aluminium Doors,Windows and Partitioin',
  },
  {
    id: 7,
    serviceName: 'Spider Glazing (All Types)',
  },
  {
    id: 8,
    serviceName: 'VS 1 System',
  },
  {
    id: 9,
    serviceName: 'Facade System',
  },
  {
    id: 10,
    serviceName: 'U Channel Glazing (All Types)',
  },
  {
    id: 11,
    serviceName: 'ACP Cladding',
  },
  {
    id: 12,
    serviceName: 'HPL Cladding',
  },
  {
    id: 13,
    serviceName: 'Zinc Composite Sheet Cladding',
  },
  {
    id: 14,
    serviceName: 'Metal Cladding',
  },
  {
    id: 15,
    serviceName: 'Perforated Sheet Cladding',
  },
  {
    id: 16,
    serviceName: 'Expanded Mesh Cladding',
  },
  {
    id: 17,
    serviceName: 'Honeycomb Panel Cladding',
  },
  {
    id: 18,
    serviceName: 'SS Cladding',
  },
  {
    id: 19,
    serviceName: 'Polycarbonate Sheet Cladding',
  },
  {
    id: 20,
    serviceName: 'Dry wall stone Cladding',
  },
  {
    id: 21,
    serviceName: 'GFRC Cladding',
  },
  {
    id: 22,
    serviceName: 'Neolith Cladding',
  },
  {
    id: 23,
    serviceName: 'Terracotta Cladding',
  },
  {
    id: 24,
    serviceName: 'Cement Board Cladding',
  },
  {
    id: 25,
    serviceName: 'False Ceiling(All Types)',
  },
  {
    id: 26,
    serviceName: 'MS Work',
  },
  {
    id: 27,
    serviceName: 'SS Work',
  },
  {
    id: 28,
    serviceName: 'Railing (All Types)',
  },
  {
    id: 29,
    serviceName: 'Blinds Work (All Types)',
  },
  {
    id: 30,
    serviceName: 'Fire Rated Doors,Windows and Partitioin',
  },
];
export default servicesData;
