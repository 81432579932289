const projectsData = [
  {
    id: 0,
    projectName: 'OPD Block,AIIMS,DELHI',
    partner: 'Ahluwalia Contracts',
    projectImages: [
      require('../images/aiims-1.jpg'),
      require('../images/aiims-2.jpg'),
    ],
  },
  {
    id: 1,
    projectName: 'Mother & Child Block,AIIMS,DELHI',
    partner: 'Ahluwalia Contracts',
    projectImages: [
      require('../images/aiims-3.jpg'),
      require('../images/aiims-4.jpg'),
    ],
  },
  {
    id: 2,
    projectName: 'Genetic Block AIIMS,DELHI',
    partner: 'Deepak Builders',
    projectImages: [
      require('../images/gen-block-aiims-1.jpg'),
      require('../images/gen-block-aiims-2.jpg'),
      require('../images/gen-block-aiims-3.jpg'),
      require('../images/gen-block-aiims-4.jpg'),
      require('../images/gen-block-aiims-5.jpg'),
      require('../images/gen-block-aiims-6.jpg'),
      require('../images/gen-block-aiims-7.jpg'),
      require('../images/gen-block-aiims-8.jpg'),
    ],
  },
  {
    id: 3,
    projectName: 'Godrej Sales Office Sector-106,Gurgaon',
    partner: 'Cherry Hill Interiors',
    projectImages: [
      require('../images/godrej-sales-cherryhill-1.jpg'),
      require('../images/godrej-sales-cherryhill-2.jpg'),
      require('../images/godrej-sales-cherryhill-3.jpg'),
      require('../images/godrej-sales-cherryhill-4.jpg'),
      require('../images/godrej-sales-cherryhill-5.jpg'),
    ],
  },
  {
    id: 4,
    projectName: 'We Work Gurugram',
    partner: 'Cherry Hill Interiors',
    projectImages: [
      require('../images/ww-gurgaon-1.jpg'),
      require('../images/ww-gurgaon-2.jpg'),
      require('../images/ww-gurgaon-3.jpg'),
      require('../images/ww-gurgaon-4.jpg'),
    ],
  },
  {
    id: 5,
    projectName: 'Unitech Business Park, Gurgaon',
    partner: 'Bestech',
    projectImages: [
      require('../images/umitech-business-park-bestech-1.jpg'),
      require('../images/umitech-business-park-bestech-2.jpg'),
      require('../images/umitech-business-park-bestech-3.jpg'),
    ],
  },
  {
    id: 6,
    projectName: 'Unitech Cyber Park, Sector-39, Gurgaon',
    partner: 'Millenium construction pvt. Ltd.',
    projectImages: [
      require('../images/umitech-cyber-park-milleniumconst-1.jpg'),
      require('../images/umitech-cyber-park-milleniumconst-2.jpg'),
      require('../images/umitech-cyber-park-milleniumconst-3.jpg'),
    ],
  },
  {
    id: 7,
    projectName: 'JBC Plywood',
    partner: '',
    projectImages: [
      require('../images/jbc-plywood-with-pvt1.jpg'),
      require('../images/jbc-plywood-with-pvt2.jpg'),
      require('../images/jbc-plywood-with-pvt3.jpg'),
    ],
  },
  {
    id: 8,
    projectName: 'Udyog Vihar, Gurgaon',
    partner: 'Private Builder',
    projectImages: [
      require('../images/udyogvihar-with-pvtbuilder1.jpg'),
      require('../images/udyogvihar-with-pvtbuilder2.jpg'),
      require('../images/udyogvihar-with-pvtbuilder3.jpg'),
    ],
  },
  {
    id: 9,
    projectName: 'ALP Airoflex, Rajasthan',
    partner: 'APS Construct Well Pvt. Ltd.',
    projectImages: [
      require('../images/airoflex-rajasthan-with-APS1.jpg'),
      require('../images/airoflex-rajasthan-with-APS2.jpg'),
      require('../images/airoflex-rajasthan-with-APS3.jpg'),
    ],
  },
  {
    id: 10,
    projectName: 'Urogyn Hospital, Bahadurgarh',
    partner: '',
    projectImages: [
      require('../images/urogyn-hos-1.jpg'),
      require('../images/urogyn-hos-2.jpg'),
      require('../images/urogyn-hos-3.jpg'),
    ],
  },
  {
    id: 11,
    projectName: 'Residential in Sisana ,Haryana',
    partner: '',
    projectImages: [
      require('../images/sisana-1.jpg'),
      require('../images/sisana-2.jpg'),
      require('../images/sisana-3.jpg'),
    ],
  },
];

export default projectsData;
